import React from "react";

const Home = React.lazy(() => import("./component/ChatRedirect"));
const ChatList = React.lazy(() => import("./component/ChatList"));
const ChatBubble = React.lazy(() => import("./component/ChatBubble"));
const Message = React.lazy(() => import("./component/ChatBubble/Message"));

const ScreenRoutes = [
  { path: "/", exact: true, name: "Home", element: Home },
  { path: "/chat", exact: true, name: "ChatList", element: ChatList },
  {
    path: "/chat_bubble/:id",
    exact: true,
    name: "ChatBubble",
    element: ChatBubble,
  },
  { path: "/chat/Message", exact: true, name: "Message", element: Message },
];

export default ScreenRoutes;
import { gql } from "@apollo/client";

export const PARTICIPANTUSER = gql`
  query {
    participantUser {
      client {
        primaryColor
        secondaryColor
      }
    }
  }
`;

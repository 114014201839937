import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, useSearchParams } from "react-router-dom";
import ScreenRoutes from "ScreenRoutes";
import Loading from "common/Loading";
import { ThemeProvider } from "@mui/styles";
import Themes from "theme";
import { ApolloProvider, useLazyQuery } from "@apollo/client";
import { PARTICIPANTUSER } from "graphql/query";
import { getClient } from "graphql/Client";


const App = () => {

  const [themeData, setThemeData] = useState("");
  const [getParticipantUser, { loading, data }] = useLazyQuery(
    PARTICIPANTUSER,
    {
      onCompleted: (res) => {
        setThemeData(res.participantUser.client);
      },
    }
  );
  useEffect(() => {
    getParticipantUser();
  }, []);

  const CustomLoading = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          background: "rgba(0,0,0,.15)",
        }}
      >
        <Loading />
      </div>
    );
  };

  return (
    <ThemeProvider theme={Themes("light", themeData)}>
      <ApolloProvider client={getClient()}>
        <Suspense fallback={<CustomLoading />}>
          <Router>
            <Routes>
              {ScreenRoutes.map((route, idx) => {
                return (
                  <Route
                    key={`Layout_${idx}`}
                    path={route.path}
                    exact={route.exact}
                    element={<route.element />}
                  />
                );
              })}
            </Routes>
          </Router>
        </Suspense>
      </ApolloProvider>
    </ThemeProvider>
  );
};

export default App;

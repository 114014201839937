import { createTheme } from "@mui/material/styles";

const Themes = (mode, themeData) => {
  const getDesignTokens = (mode) => ({
    palette: {
      mode,
      primary: {
        main: "#001E3C",
      },
      background: {
        ...(mode === "light"
          ? {
            default: "#fff",
          }
          : {
            default: "#121212",
          }),
      },
      text: {
        ...(mode === "light"
          ? {
            primary: "rgba(0, 0, 0, 0.87)",
            secondary: "rgba(0, 0, 0, 0.6)",
            disable: "rgba(0, 0, 0, 0.38)",
          }
          : {
            primary: "#fff",
            secondary: "rgba(255, 255, 255, 0.7)",
            disable: "rgba(255, 255, 255, 0.5)",
          }),
      },
    },
    chat: {
      mode,
      primary: {
        main: "#ffffff",
        secondary: "#02a682",
        header: {
          ...(mode === "light"
            ? {
              default: "#015e54",
            }
            : {
              default: "#1f2c34",
            }),
        },
        bg: {
          ...(mode === "light"
            ? {
              default: "#cbcbcb",
            }
            : {
              default: "#0e1715",
            }),
        },
      },
      color: {
        ...(mode === "light"
          ? {
            primary: "#ffffff",
            secondary: "#e2ffc7",
          }
          : {
            primary: "#1f2c34",
            secondary: "#005d4b",
          }),
      },
      text: {
        ...(mode === "light"
          ? {
            primary: "#1c1d1d",
            secondary: "#1c1d1d",
          }
          : {
            primary: "#fff",
            secondary: "rgba(255, 255, 255, 0.7)",
          }),
      },
    },
  });

  const theme = createTheme(getDesignTokens(mode), [mode]);

  return theme;
};

export default Themes;

import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  from,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { relayStylePagination } from "@apollo/client/utilities";
import { createUploadLink } from "apollo-upload-client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { onError } from "@apollo/client/link/error";
import { SubscriptionClient } from "subscriptions-transport-ws";
import { getMainDefinition } from "apollo-utilities";
import { parseCookies } from "nookies";

export const getClient = () => {

  const token = localStorage.getItem('chatToken');

  const authLink = setContext((_, { headers }) => {

    return {
      headers: {
        ...headers,
        Authorization: token,
      },
    };
  });

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      // Graphql Error handling
      if (graphQLErrors) {
        graphQLErrors.map(({ message, extensions, locations, path }) => {
          return console.log(message);
        });
      }

      graphQLErrors.map(({ message, locations, path }) => {

        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`
        )
      });

      // If network error happening
      if (networkError) {
        return console.log(`[Network error]: ${networkError}`);
      }
    }
  );

  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const link = from([errorLink, new createUploadLink({ uri: BASE_URL })]);

  const wsLink = new WebSocketLink({
    uri: `wss://chatdev.w3kernel.com/graphql/?token=${token}`,
    options: {
      reconnect: true,
    },
  });

  const splitLink = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    authLink.concat(link)
  );

  const options = {
    typePolicies: {
      Query: {
        fields: {
          userConversationMessages: relayStylePagination(),
        },
      },
    },
  };

  const client = new ApolloClient({
    cache: new InMemoryCache(options),
    link: splitLink,
    // networkInterface: wsLink
  });

  return client;
};
